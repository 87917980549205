module.exports = {
  theme: {
    colors: {
      primary: '#d70b36',
      black: '#333333'
    },
    fonts: {
      sans: [
        'Source Sans Pro',
        'Arial',
        'sans-serif'
      ]
    },
    typography: {
      body: {
        font: 'sans',
        leading: 'md',
        color: 'black',
        weight: 'normal',
        size: 'md'
      },
      title: {
        leading: 'sm',
        weight: 'bold'
      },
      paragraph: {
        leading: 'lg',
        weight: 'light'
      },
      link: {
        color: 'primary'
      }
    },
    border: {
      radius: '0'
    }
  }
}
