
export default {
  vessel: {
    title: {
      fontWeight: 'semibold',
      fontSize: 'md',
      marginBottom: { all: 'md', md: '0' }
    }
  }
}
