
export default {
  vessel: {
    button: {
      backgroundColor: 'primary',
      color: 'white',
      borderRadius: 'full',
      width: 'sm',
      height: 'sm'
    }
  },
  watch: {
    '$i18n.locale': '$fetch'
  },
  async fetch() {
    const { $content, $i18n: { locale } } = this
    const stores = await $content(locale, 'stores').only(['name', 'phone']).fetch()

    this.stores = stores
  },
  data() {
    return {
      stores: []
    }
  },
  computed: {
    items() {
      return this.stores.map(({ name, phone }) => {
        return { name, phone: Array.isArray(phone) ? phone[0] : phone }
      })
    }
  }
}
