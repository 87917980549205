
import { toCamel, toKebab, toSnake } from 'vessel/utils'

export default {
  vessel: {
    banner: {
      backgroundColor() {
        return this.config.background ?? 'primary'
      },
      color() {
        return this.config.color ?? 'white'
      },
      overflow: 'hidden'
    }
  },
  data() {
    return {
      showImages: false
    }
  },
  computed: {
    showBanner() {
      return this.config.enabled == true
    },
    link() {
      const path = this.config?.link ?? 'offers'
      return this.$pagePath(path)
    }
  },
  methods: {
    text(key) {
      const type = toCamel(this.config.type)
      return this.$t(`promoBanner.${type}.${key}`)
    },
    image(side) {
      const type = toKebab(this.config.type)
      return `/banners/${type}-banner-${side}.svg`
    },
    onClick() {
      const type = toSnake(this.config.type)
      const data = { category: 'Banners', label: `${type}_banner` }

      this.$tracker.trackEvent('banner_click', data)
    }
  },
  created() {
    this.config = this.$config.promoBanner || {}
  },
  mounted() {
    if (!this.showBanner) return

    if (this.$screen.md) {
      setTimeout(() => this.showImages = true, 500)
    } else {
      this.showImages = true
    }
  }
}
