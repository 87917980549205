
import Taxonomy from '@/graphql/queries/taxonomies/Taxonomy'

export default {
  vessel: {
    store: {
      paddingX: '2xs',
      paddingBottom: '2xs',
      borderBottomWidth: '2',
      borderColor: 'transparent',
      cursor: 'pointer',
      fontWeight: 'semibold',
      fontSize: 'sm'
    },
    activeStore: {
      $extend: 'store',
      borderColor: 'primary',
      color: 'primary'
    }
  },
  watch: {
    '$i18n.locale': '$fetch'
  },
  async fetch() {
    const { data } = await this.$graphql.query({
      clientId: 'eshop',
      query: Taxonomy,
      variables: { where: { kind: 'category' }, children: 5 }
    })

    this.categories = data.children.map(taxon => ({
      label: taxon.name,
      path: { name: 'categories-id', params: { id: taxon.slug } },
      translate: false
    }))

    this.stores = await this.$content(this.$i18n.locale, 'stores').fetch()
    this.activeStore = this.stores[0]
  },
  data() {
    return {
      quickLinks: [
        {
          path: 'about',
          label: 'footer.theCompany',
          translate: true
        },
        {
          path: 'products',
          label: 'pages.products',
          translate: true
        },
        {
          path: 'services',
          label: 'pages.services',
          translate: true
        },
        {
          path: 'service-trakter',
          label: 'pages.service',
          translate: true
        },
        {
          href: 'http://paourisparts.gr',
          label: 'footer.tractorParts',
          translate: true
        }
      ],
      categories: [],
      activeStore: null,
      stores: []
    }
  }
}
