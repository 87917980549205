export default (ctx) => {
  if (!ctx.isDev) {
    ctx.app.head.script = [
      { hid: 'clickcease', src: 'https://www.clickcease.com/monitor/stat.js', async: true },
      ...(ctx.app.head.script || [])
    ]

    ctx.app.head.noscript = [
      { hid: 'clickcease', innerHTML: "<a href='https://www.clickcease.com' rel='nofollow'><img src='https://monitor.clickcease.com/stats/stats.aspx' alt='ClickCease'/></a>" },
      ...(ctx.app.head.noscript || [])
    ]
  }
}
