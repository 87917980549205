export default async () => {
  const options = {"locale":"el_GR","color":"","pageId":"1714754192074049"}

  if (options.enabled !== false && typeof window !== 'undefined') {
    /* eslint-disable */
    (function(d, s, id) {
      var js, fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) return;
      js = d.createElement(s); js.id = id;
      js.src = `https://connect.facebook.net/${options.locale}/sdk/xfbml.customerchat.js`;
      fjs.parentNode.insertBefore(js, fjs);
    }(document, 'script', 'facebook-jssdk'));
    /* eslint-enable */

    window.fbAsyncInit = function() {
      FB.init({
        xfbml : true,
        version : 'v10.0'
      })
    }

    const container = document.createElement('div')
    const setAttr = (key, value) => value && container.setAttribute(key, value)

    setAttr('class', 'fb-customerchat')
    setAttr('attribution', 'setup_tool')

    setAttr('page_id', options.pageId)
    setAttr('theme_color', options.color)
    setAttr('logged_in_greeting', options.message)
    setAttr('logged_out_greeting', options.guestMessage)

    const fbRoot = document.createElement('div')
    fbRoot.setAttribute('id', 'fb-root')

    document.body.appendChild(fbRoot)
    document.body.appendChild(container)
  }
}
