
import { sync } from 'vuex-pathify'

export default {
  vessel: {
    wrapper: {
      position: 'fixed',
      pinBottom: true,
      pinRight: true,
      margin: 'sm',
      shadow: true,
      zIndex: 50,
      lineHeight: 'sm',
      fontSize: 'sm',
      maxWidth: 'full',
      sm: {
        width: '5xl'
      },
      md: {
        margin: 'md'
      }
    },
    title: {
      fontSize: 'lg',
      font: 'title',
      fontWeight: 'bold',
      paddingRight: 'md'
    },
    text: {
      fontWeight: 'normal'
    },
    icon: {
      fontSize: 'lg',
      cursor: 'pointer',
      padding: '2xs',
      margin: 'sm',
      backgroundColor: 'white',
      color: 'primary',
      shadow: true,
      borderRadius: 'full'
    }
  },
  props: {
    notification: {
      type: Object,
      default: null
    }
  },
  computed: {
    visible: sync('notificationPopup')
  }
}
