
export default {
  head() {
    return this.$seoMeta.head(this, {
      siteName: this.$t('siteName'),
      siteDesc: this.$t('home.metaTitle')
    })
  },
  vessel: {
    topbar: {
      paddingX: 'sm',
      paddingY: 'sm',
      backgroundColor: 'white'
    },
    footer: {
      textColor: 'black',
      fontWeight: 'light',
      overflow: 'hidden'
    }
  },
  watch: {
    '$i18n.locale': '$fetch',
    notification(newValue, oldValue) {
      if (!oldValue && newValue) {
        this.$modal.open('notification-popup')
      }
    }
  },
  async fetch() {
    const { $content, $i18n: { locale } } = this
    this.stores = await $content(locale, 'stores').only('phone').fetch()
    this.notification = await $content(locale, 'notification').fetch()
  },
  data() {
    return {
      stuck: false,
      stores: [],
      notification: null
    }
  },
  computed: {
    border() {
      const border = !this.$route.name.startsWith('index') || this.stuck
      return border ? true : 'transparent'
    },
    phones() {
      return this.stores.map(({ phone }) => {
        return Array.isArray(phone) ? phone[0] : phone
      })
    }
  }
}
