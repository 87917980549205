
export default {
  props: {
    example: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    examples() {
      return this.example
        ? ['Kubota', 'Yanmar', this.$t('shredder'), this.$t('tiller')]
        : []
    }
  },
  methods: {
    focusInput() {
      this.$refs.search.focusInput()
    },
    onChange(value) {
      if (value) {
        const route = {
          name: 'products',
          query: {
            search: value
          }
        }

        this.$navigateTo(route)
      }
    }
  }
}
